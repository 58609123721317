<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn text v-bind="attrs" v-on="on">Enviar por correo</v-btn> -->
      <v-list-item-title v-bind="attrs" v-on="on"> <v-icon dense color="grey darken-2">
          mdi-email-fast
        </v-icon> Enviar recibo</v-list-item-title>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Enviar invitación</span>
      </v-card-title>
      <v-card-text class="bg-surface-light pt-4">
        Se enviara una invitación a realizar el pago pendiente al titular mediante el <strong>correo ingresado</strong>
        anexando el recibo de pago.
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="sendEmail">
          <v-text-field v-model="email" label="Correo electrónico" :rules="[rules.required, rules.email]"
            required></v-text-field>
          <!-- <v-text-field
              v-model="subject"
              label="Asunto"
              :rules="[rules.required]"
              required
            ></v-text-field>
            <v-textarea
              v-model="message"
              label="Mensaje"
              :rules="[rules.required]"
              required
            ></v-textarea> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="sendEmail">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '../plugins/event-bus'; // Importa el bus de eventos

export default {
  computed: {
    ...mapGetters(['getEmail'])
  },
  mounted() {
    console.log(this.propEmail)
    console.log(this.getEmail)
    this.email = this.propEmail ? this.propEmail: this.getEmail
  },
  props: {
    ReciboId: {
      type: String,
      required: true,
      default: ''
    },
    Membresia: {
      type: String,
      required: true,
      default: ''
    },
    Recibo: {
      type: String,
      default: ''
    },
    Titular: {
      type: String,
      required: true,
      default: ''
    },
    Monto: {
      type: String,
      default: ''
    },
    propEmail: {
      type: String,
      required: false,
      default:''
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      email: '',
      subject: '',
      message: '',
      rules: {
        required: value => !!value || 'Requerido.',
        email: value => {
          const pattern = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/
          return pattern.test(value) || 'Correo electrónico inválido.'
        }
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      
    },
    sendEmail() {
      this.$loading(true)
      /*const data = {
        Membresia: this.Membresia,
        ReciboId: this.ReciboId,
        Recibo: this.Recibo,
        Titular: this.Titular,
        Monto: this.Monto,
        Correo: this.email
      };
      const formData = new FormData();
      formData.append('Membresia', this.Membresia);
      formData.append('ReciboId', this.ReciboId);
      formData.append('Recibo', this.Recibo);
      formData.append('Titular', this.Titular);
      formData.append('Monto', this.Monto);
      formData.append('Correo', this.email);
      if (this.$refs.form.validate()) {
        fetch(`${process.env.VUE_APP_NOTIFICACIONES}ReciboPagoSendEmail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then(response => {
            if (!response.ok) {
              this.$swal({
                position: '',
                html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> . ${response.statusText}`,
                icon: 'error',
                confirmButtonText: 'Cerrar',
                showCloseButton: true,
                showConfirmButton: true,
                //timer: 1500,
              })
            }
            this.$loading(false)
            return response.json(); // Esto convierte la respuesta en JSON
          })
          .then(data => {
            console.log(data)
            this.$loading(false)
            this.$swal({
              position: 'top-end',
              title: 'Correcto',
              text: 'Fue enviado el correo',
              icon: 'success',
              confirmButtonText: 'Cerrar',
              showCloseButton: false,
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(error => {
            this.$loading(false)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
              //timer: 1500,
            })
          });
      }*/
 

      if (this.$refs.form.validate()) {
        fetch(`${process.env.VUE_APP_NOTIFICACIONES}ReciboMedicover/SendMail/${this.email}/${this.ReciboId}`, {
          method: 'GET'
        })
          .then(response => {
            console.log(response)
            if (response.status !== 200) {
              this.$swal({
                position: '',
                html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> . ${response.statusText}`,
                icon: 'error',
                confirmButtonText: 'Cerrar',
                showCloseButton: true,
                showConfirmButton: true,
                //timer: 1500,
              })
            }
            this.$loading(false)
            return response.status 
          })
          .then(data => {
            console.log(data)
            this.$loading(false)
            EventBus.$emit('show-snackbar', {
              text: `El correo fue enviado correctamente.`,
              color: 'success',  // Puedes cambiar el color
              timeout: 5000   // Puedes ajustar el tiempo
            });
          })
          .catch(error => {
            this.$loading(false)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
              //timer: 1500,
            })
          });
      }
      
      // Reiniciar el formulario y cerrar el modal
      // this.email = '';
      this.dialog = false;
    }
  }
}
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>