<template>
    <v-container>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
        <h1 class="text-h4 font-weight-thin mb-4">
          Asitencia
        </h1>
        <p>Da clic para registrar tu asistencia.</p>
        <v-btn
          color="success"
          class="mr-4"
          @click="registrar()"
          >
          Registrar
        </v-btn>
        </v-col>
        <v-row>
      <!--      <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Fecha"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
  
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            Seleccionar
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>-->
       <!-- <v-col
          cols="12"
          sm="6"
          md="4"
          lg="6"
        >
            <v-text-field
            v-model="search"
            label="Buscar"
            single-line
            hide-details
  
        ></v-text-field>
        </v-col>-->
      </v-row>
      <div class="container-fluid mt-4">
        <v-data-table
            dense
            fixed-header
            :headers="headers"
            :items="listado"
            item-key="id"
            :search="search"
            >
        </v-data-table>
      </div>
    </v-container>
    </template>
  <script>
import { mapGetters } from 'vuex'
import EndPointNet from '@/functions/axios/axios-bearer'
  export default {
    computed: {
    ...mapGetters(['getIDE'])
  },
  async mounted () {
    await this.getAsistencia()
  },
  methods: {
    
    async registrar () {
      const response = await EndPointNet(
        `Asistencia`,
        'POST',
        {
          idUser: this.getIDE
        }
      )
      console.log(response)
  
      this.$swal({
        position: 'center',
        icon: 'success',
        title: 'Su asistencia fue registrada.',
        showConfirmButton: false,
        timer: 1500
      })
      await this.getAsistencia()
    },
    async getAsistencia () {
      console.log(this.date)
      const response = await EndPointNet(
        `Asistencia/${this.getIDE}`,
        'GET',
        {
          //iduser: this.getIDE,
          //date: this.date
        }
      )
      if (response.status === 200) {
        this.listado = response.data
      } else {
        alert('error')
      }
    }
  },
  data () {
    return {
      menu: false,
      dates: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      search: '',
      listado: [],
      headers: [
        { text: 'Fecha', value: 'Registro' },
        { text: 'Id', value: 'Id' },
        { text: 'IP', value: 'IP' }
      ],
      dialog: false
    }
  }
  }
  </script>
    <style lang="scss" scoped></style>
  