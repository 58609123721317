<template>
 <v-container>
    <v-row justify="center">
      <v-col class="text-center" cols="12">
        <div class="responsive-text-h1">
          Las coberturas médicas que tú y tu familia necesitan
        </div>
        <!-- <h4 class="subheading">
          El mejor complemento para tú seguro de gastos médicos mayores con las coberturas médicas esenciales
        </h4> -->
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="item in items" :key="item.src" cols="6" sm="3">
        <div class="text-h5 font-weight-thin mb-2 text-center">
          <v-icon size="34px">
            mdi-marker-check
          </v-icon>{{ item.nombre }}
        </div>
        <v-img :style="`max-width:${imgCobertura.width};height:126px`" class="bounce-enter-active" :src="item.src"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
export default {
  name: 'SeccionCobComponent',
  data: function () {
    return {
      items: [
          {
            src:`${process.env.VUE_APP_BASE_IMG}/coberturas/urgencia.png`,
            nombre: 'Urgencias'
          },
          {
            src: `${process.env.VUE_APP_BASE_IMG}/coberturas/consulta.png`,
            nombre: 'Consultas'
          },
          {
            src: `${process.env.VUE_APP_BASE_IMG}/coberturas/dental.png`,
            nombre: 'Dental'
          },
          {
            src: `${process.env.VUE_APP_BASE_IMG}/coberturas/visual.png`,
            nombre: 'Visual'
          }
        ],
    };
  },
  computed: {
    imgCobertura(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
              return {
                width: '200px'
              };
            case 'sm':
              return {
                width: '200px'
              };
            case 'lg':
              return {
                width: '250px'
              };
            case 'xl':
              return {
                width: '300px'
              };
            default:
              return {
                //height: '850px'
              };
          }
        }
      }
}
</script>
<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.7s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}
.text-xs-mobile {
    font-size: 14px;
  }

  @media only screen and (min-width: 600px) {
    .text-xs-mobile {
      font-size: inherit; /* Restablece el tamaño original para pantallas grandes */
    }
  }
</style>