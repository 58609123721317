<template>
  <v-container>
      <h2 class="grey--text"> Médicos especialistas </h2>
      <v-subheader class="pa-0">Selecciona una especialidad para ver los médicos a los que puedes acudir.</v-subheader>
      <v-row>
        <v-col cols="12" md="5" lg="5" sm="12">
          <v-combobox  clearable dense hide-selected outlined v-model="select" :items="items" item-text="Especialidad"
        item-value="IdEsp" label="Elige especialidad" persistent-hint return-object single-line width="140"
        @change="onChangeEsp"></v-combobox>
        </v-col>
      </v-row>
        <v-row>
        <v-col v-for="(doctor, index) in doctors" :key="index" cols="12" sm="2" md="4" lg="4">
          <v-card class="text-center">
            <v-avatar size="80" class="mx-auto mt-1">
              <img src='https://medicover.mx/img/defaultdoctor.jpg' :alt="'avatar:' + doctor.idusuario" />
            </v-avatar>
            <h3 class="title">{{ doctor.Nombre }}</h3>
            <!-- <v-card-title class="justify-center"> {{ doctor.Nombre }}</v-card-title> -->
            <v-card-subtitle>
              <v-rating v-model="rating" length="5" color="blue" density="compact" half-increments pt-1
                size="14"></v-rating>
              <small>
               {{ doctor.Especialidad }} 5.0 ({{ doctor.NumConsultas }})
              </small></v-card-subtitle>

            <v-card-text>
              <!-- Tel: {{ doctor.Tel }} <br />
              Consultorio: {{ doctor.Consultorio }} <br />
              {{ doctor.Direccion }} -->
              <span class="text--small">{{ doctor.Tel }}</span><br/>
              <span class="text--small">Consultorio : {{ doctor.Consultorio }}</span><br/>
              <span class="text--small">{{ doctor.Direccion }}</span>
            </v-card-text>

            <v-card-actions class=" justify-center">
              <v-btn color="primary" :href="'tel:' + doctor.Tel" small outlined>
                Llamar
              </v-btn>
              <v-btn :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(doctor.Direccion)}`"
                target="_blank" color="primary" small >
                Ver Google Maps
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>
<script>
import ApiBase from '@/functions/axios/axiosNetBase'
export default {
  async mounted() {
    await this.getEspecialidades()
  },
  methods: {
    handleRowClick(item) {
      if (window.getSelection().toString().length === 0) {
        // console.log(JSON.parse(localStorage.getItem('permisos')))
        this.nombredoctor = item.nombre + ' ' + item.apaterno + ' ' + item.amaterno
        this.telefono = item.tels // Use router.push here
        this.$swal({
          // position: 'top-end',
          title: 'Doctor',
          // input: 'text',
          icon: 'phone',
          html:
            '<b>' + this.nombredoctor + '</b>,<br/>' +
            '<br/><a href="tel:' + this.telefono + '"><h2 style="color:blue">Llamar</h2></a><br/> ',
          confirmButtonText: 'Cerrar',
          showCloseButton: true,
          showConfirmButton: true,
          closeButtonText: 'cerrar',
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6'
          // timer: 1500,
        }).then((data) => {
          console.log(data)
        })
      }
    },
    llamando(telefono) {
      this.telefono = telefono // Use router.push here
      window.location.href = 'tel:' + telefono
      // window.open('tel:' + telefono)
    },
    onBusca(value) {
      console.log(value)
    },
    async getEspecialidades(){
      var url = `getEspecialidades`;
        ApiBase(
          url,
          'GET',
          {
            all: null  // especialidadId || null Enviar el parámetro solo si tiene valor
          }
        ).then((response) => {
          if (response.status === 200) {
            if (response.data) {
              this.items = response.data
            }
          }
        })
          .catch((error) => {
            this.$loading(false)
            console.log(error)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado, <b>intente mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
            })
          });
    },
    onChangeEsp(event) {
      this.idespecialidad = event.IdEsp
      this.nombrePlan = event.Especialidad
      this.getDoctors()
    },
    onChangePago(event) {
      this.pagos = event.num
      this.Calculos()
    },
    async onChangeCheck() {
      this.getDoctors()
    },
    async getDoctors() {
      this.$loading(true)
      if (this.idespecialidad !== '') {
        this.$loading(true)
        var url = `getMedicosEspecialidad?IdEsp=${this.idespecialidad}`;
        ApiBase(
          url,
          'GET'
        ).then((response) => {
          this.$loading(false)
          if (response.status === 200) {
            if (response.data) {
              this.doctors = response.data
            }
          }
        })
          .catch((error) => {
            this.$loading(false)
            console.log(error)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado, <b>intente mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
            })
          });
      }
    }
  },
  data() {
    return {
      rating: 5,
      nombredoctor: '',
      telefono: '',
      ancho: window.innerWidth,
      largo: window.innerHeigth,
      name: 'Cotizador',
      title: 'Cotizador',
      select: '',
      // select: { nombre: 'Plan', consultas: '0' },
      pagodefault: { num: 1, nombre: 'ANUAL' },
      items: [],
      search: '',
      doctors: [],
      headersdoctors: [
        {
          text: 'Nombres',
          align: 'start',
          filterable: false,
          value: 'nombre'
        },
        { text: 'Apellido', value: 'apaterno' },
        { text: 'Apellido', value: 'amaterno' },
        { text: 'Correo', value: 'mail' },
        { text: 'Dirección', value: 'direccion' },
        { text: 'Teléfono', value: 'tels' }
      ],
      diferidos: [
        { num: 1, ab: 'ANUAL' },
        { num: 2, ab: 'SEMESTRAL' },
        { num: 4, ab: 'TRIMESTRAL' },
        { num: 12, ab: 'MENSUAL' }
      ],
      urgencias: true,
      consultas: true,
      dental: false,
      visual: false,
      subtotal: '$0.00',
      btnpdf: true,
      total: '$0.00',
      totalsinformato: 0,
      pagos: 1,
      financiamiento: '$0.00',
      nombrePlan: '',
      idespecialidad: '',
      primaneta: '$0.00',
      iva: '$0.00',
      exp: '$0.00',
      subsecuentes: '$0.00',
      primerpago: '$0.00',
      fecha: '',
      red: ''
    }
  }
}
</script>
<style lang="scss"></style>
