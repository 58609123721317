var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('p',[_vm._v("Listado de hospitales Red MediCover.")]),_c('capacitacion',{attrs:{"isOpen":_vm.isModOpen,"propId":_vm.selectedRow},on:{"update:isOpen":function($event){_vm.isModOpen=$event},"update:is-open":function($event){_vm.isModOpen=$event}}}),_c('div',{staticClass:"cont-bord-gris"},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"xs":"6","sm":"6","md":"4","lg":"4","xl":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar en tabla","single-line":"","hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.comp_change()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"custom-data-table",attrs:{"items-per-page":-1,"fixed-header":"","calculate-widths":"","headers":_vm.headers,"items":_vm.data,"search":_vm.search,"item-key":"Id","footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Filas por página'
            },"no-data-text":"No hay datos disponibles"},on:{"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:`item.ops`,fn:function({ item }){return [_c('v-menu',{attrs:{"offset-x":"","left":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',[_c('v-btn',_vm._g(_vm._b({class:{ 'selected-row': _vm.selectedRow && _vm.selectedRow === item.Ide },attrs:{"red":"","icon":""},on:{"click":function($event){return _vm.selectRow(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical-circle-outline")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.addCap(item.Id)}}},[_c('v-icon',{attrs:{"left":"","dense":"","color":"grey darken-2"}},[_vm._v(" mdi-eye-outline ")]),_vm._v(" Registrar capacitación ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.verCap(item.Id)}}},[_c('v-icon',{attrs:{"left":"","dense":"","color":"grey darken-2"}},[_vm._v(" mdi-list-box-outline ")]),_vm._v(" Ver capacitaciones ")],1)],1)],1)]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }