<template>
  <v-container>
    <div class="d-flex flex-wrap align-center justify-end">
      <v-chip class="ma-1" color="azul_med" label dark>
        <v-icon  start>mdi-chevron-right-circle-outline</v-icon>
        <v-list-item-subtitle>ACTIVAS: {{ this.activas }}</v-list-item-subtitle>
      </v-chip>
      <v-chip class="ma-1" color="grey" label dark>
        <v-icon  start>mdi-alert-circle-outline</v-icon>
        <v-list-item-subtitle>SUSPENDIDAS: {{ this.suspendidas }}</v-list-item-subtitle>
      </v-chip>
      <v-chip class="ma-1" color="grey" label dark>
        <v-icon start>mdi-alert-circle-outline</v-icon>
        <v-list-item-subtitle>BAJAS: {{ this.totalbajas }}</v-list-item-subtitle>
      </v-chip>
    </div>
    <p class="font-weight-regular">
      Listado de <strong>membresías</strong>, puedes agrupar por colectividad y/o Agente.
    </p>

    <v-row class="bordered-row">
      <v-col cols="12" md="3">
        <list-agentes @agent-change="changeAgent"></list-agentes>
      </v-col>
      <v-col cols="12" md="3">
        <list-colectivos :propAdmin="true" @colectivo-change="changeColectividad"></list-colectivos>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn color="azulfuerte" @click.native="buscarColectivo" dark>Buscar</v-btn>
      </v-col>
      <v-col class="">
        <v-btn color="azul_med" v-show="verOptDownloadCole" @click.native="DescargaZip" dark>Descargar tarjetas
          .zip</v-btn>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col sm="12" md="4" lg="4" xl="4">
        <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
          outlined dense @change="comp_change()"></v-text-field>
      </v-col>
    </v-row>
    <!--group-by="AgeNom" show-select -1       fixed-header
      height="400px"
      show-select       v-model="selected"
      -->
    <v-data-table :items-per-page="5" calculate-widths :headers="headers" :items="contratos" item-key="GUID"
      :search="search" :group-by="['Agente']" 
       no-data-text="No hay datos disponibles"
      :footer-props="customFooterProps">
      <template v-slot:[`item.index`]="{ index }">
        <td>{{ index + 1 }}</td>
      </template>
      <template v-slot:[`item.Plan`]="{ item }">
        <div class="card">
          <div class="card-header">{{ item.Plan }} </div>
          <div class="card-body">
            Citas restantes: {{ item.NumCitas }} <br />
            Urgencias: {{ $formatCurrency(item.MontoUrgencias) }} <br />
            Coberturas: {{ item.Coberturas }} <br />
            Tipo pago: {{ item.TipoPago}} <br />
          </div>
          <!-- <div class="card-footer">
            <button>Acción</button>
          </div> -->
        </div>
        <!-- <v-card color="azul_med" dark class="mini-card">
          <v-card-title style="font-size: 0px;margin: 6px;padding: 0%;display: match;">{{ item.Plan }}</v-card-title>
          <v-card-text style="font-size: 10px;margin: 6px; margin-top: 0%;">
            <p style="margin: 0%;padding: 0%;">
              Citas restantes: <strong>{{ item.NumCitas }}</strong><br />
              Urgencias: <strong>{{ $formatCurrency(item.MontoUrgencias) }}</strong><br />
              Coberturas: <strong>{{ item.Coberturas }}</strong>
            </p>
          </v-card-text>
        </v-card> -->
      </template>
      <template v-slot:[`item.ContNumTel`]="{ item }">
        <td><small><a v-if="item.ContNumTel" @click="$copyText(item.ContNumTel)">{{ item.ContNumTel }} </a><br />
            <a @click="$copyText(item.ContEmail)" v-if="item.ContEmail">{{ item.ContEmail
              }}</a></small></td>
      </template>
      <template v-slot:[`item.GUID`]="{ item }">
        <td>{{ item.GUID }}</td>
      </template>
      <!-- @click:row="handleRowClick" -->
      <template v-slot:[`item.Estatus`]="{ item }">
        <td><span v-if="item.Estatus === 'ACTIVO'" class="estatus-activo">{{ item.Estatus }}</span>
          <span v-if="item.Estatus === 'SUSPENDIDO'" class="estatus-impago">{{ item.Estatus }}</span>
          <span v-if="item.Estatus === 'BAJA'" class="estatus-expirado">{{ item.Estatus }}</span>
        </td>
      </template>
      <template v-slot:[`item.MontoUrgencias`]="{ item }">
        <td>
          <span v-if="item.MontoUrgencias === 0" class="urgencias0">
            {{ $formatCurrency(item.MontoUrgencias) }}
          </span>
          <span v-else>
            {{ $formatCurrency(item.MontoUrgencias) }}
          </span>
        </td>
      </template>
      <template v-slot:[`item.FecIniVig`]="{ item }">
        <td>{{ $formatDate(item.FecIniVig) }}</td>
      </template>
      <template v-slot:[`item.FecAlta`]="{ item }">
        <td>{{ $formatDate(item.FecAlta) }}</td>
      </template>
      <template v-slot:[`item.DatosActualizadosPortal`]="{ item }">
        <td> <span v-if="item.DatosActualizadosPortal"> {{ $formatDate(item.DatosActualizadosPortal) }}</span>
          <span v-else><v-icon dense color="red darken-2">
              mdi-close
            </v-icon></span>
        </td>
      </template>
      <template v-slot:[`item.Titular`]="{ item }">
        <td><small> {{ item.Titular }}</small></td>
      </template>
      <template v-slot:[`item.Contratante`]="{ item }">
        <td>
          <v-chip v-if="item.Colectividad"
            style="max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="ma-1" label
            text-color="greey" x-small>
            <v-icon left>
              mdi-label
            </v-icon>
            {{ item.Colectividad }}
          </v-chip><br />
          <small
            v-if="item.Contratante.replace(/\s+/g, '').toUpperCase() != item.Titular.replace(/\s+/g, '').toUpperCase()">
            {{ item.Contratante }}
          </small>
        </td>
      </template>
      <template v-slot:[`item.Colectividad`]="{ item }">
        <td>
          <small>{{ item.Colectividad }}</small>
        </td>
      </template>
      <template v-slot:[`item.Membresia`]="{ item }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn red icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :disabled="(item.Estatus === 'BAJA')" @click="editItem(item)">
              <v-list-item-title>
                <ButtonMembresia :propObjTarjet="item"></ButtonMembresia>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="verPagos(item.GUID, item.TotRecibos)">
              <v-list-item-title> Ver recibos</v-list-item-title>
            </v-list-item>
            <v-list-item @click="verBeneficiarios(item.GUID)">
              <v-list-item-title> Ver Beneficiarios</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <list-pagos :isOpen.sync="isModLisPagos" :Membresia="this.MembresiaSelec"
      :NumRecibos="this.NumRecibosSelec"></list-pagos>
    <list-beneficiarios :isOpen.sync="isModLisBen" :Membresia="this.MembresiaSelec"></list-beneficiarios>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApiBase from '@/functions/axios/axiosNetBase'


export default {
  name: 'Membresias',
  components: {
    ButtonMembresia: () => import('@/components/ButtonMembresia.vue'),
    ListColectivos: () => import('@/components/Forms/ColectivosListComponent.vue'),
    ListPagos: () => import('@/components/ListPagoComponent.vue'),
    ListAgentes: () => import('@/components/Forms/AgentesListComponent.vue'),
    ListBeneficiarios: () => import('@/components/ListBeneficiariosComponent'),
  },
  computed: {
    ...mapGetters(['getCODAGE'])
  },
  mounted() {
    this.getMembresiasAgente()
  },
  data() {
    return {
      //selected: [],
      verOptDownloadCole: false,
      grouped: {},
      totalbajas: 0,
      MembresiaSelec: null,
      NumRecibosSelec: 0,
      isModLisBen: false,
      isModLisPagos: false,
      idcolectividad: null,
      idagente: null,
      activas: 0,
      suspendidas: 0,
      search: '',
      contratos: [],
      headers: [
        { text: "#", value: "index", align: "start" },
        { text: 'Agente', value: 'Agente', sortable: false },
        { text: 'Membresia', value: 'GUID', sortable: true },
        //{ text: 'Citas', value: 'NumCitas', sortable: false },
        //{ text: 'Urgencias', value: 'MontoUrgencias', sortable: false },
        { text: 'Plan', value: 'Plan', sortable: false },
        //{ text: 'Coberturas', value: 'Coberturas', sortable: false },
        { text: 'Vigencia Inicio', value: 'FecIniVig', sortable: true },
        { text: 'Alta', value: 'FecAlta', sortable: true },
        { text: 'Contratante', value: 'Contratante', sortable: false, whidt: 100 },
        { text: 'Titular', value: 'Titular', sortable: false },
        //{ text: 'Colectividad', value: 'Colectividad', sortable: false },
        { text: 'Contacto', value: 'ContNumTel', sortable: false },
        //{ text: 'Recibos', value: 'TotRecibos', sortable: false },
        { text: 'Estatus', value: 'Estatus', sortable: true },
        { text: 'Opciones', value: 'Membresia', sortable: false },
      ],
      customFooterProps: {
        itemsPerPageText: "Elementos por página:", // Texto para "Items per page"
        itemsPerPageAllText: "Todos", // Texto personalizado para "All"
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        'items-per-page-text': 'Filas por página'
      }
    }
  },
  methods: {
    verPagos(mem, numrecibos) {
      this.MembresiaSelec = mem
      this.NumRecibosSelec = numrecibos
      this.isModLisPagos = true
    },
    verBeneficiarios(mem) {
      this.MembresiaSelec = mem
      this.isModLisBen = true
    },
    buscarColectivo() {
      this.getMembresiasAgente()
    },
    changeColectividad(value) {
      if (value) {
        this.idcolectividad = value
        this.verOptDownloadCole = true
      } else {
        this.idcolectividad = null
        this.verOptDownloadCole = false
      }
    },
    changeAgent(value) {
      this.idagente = value
    },
    getMembresiasAgente() {
      this.$loading(true)
      //var url = this.idcolectividad !== null ? `getMembresias?CodCole=${this.idcolectividad}` : `getMembresias`
      var url = `getMembresias?Agente=${this.idagente}&Colectivo=${this.idcolectividad}`
      ApiBase(
        url,
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.contratos = response.data
            this.activas = this.contratos.filter(item => item.Estatus === 'ACTIVO').length;
            this.suspendidas = this.contratos.filter(item => item.Estatus === 'SUSPENDIDO').length;
            this.totalbajas = this.contratos.filter(item => item.Estatus === 'BAJA').length;
            this.$loading(false)
          }
        }
      })
        .catch(() => {
          this.$loading(false)
          this.$swal({
            position: '',
            html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
          })
        });
    },
    DescargaZip() {
      this.$loading(true)
      var urltarjet = `${process.env.VUE_APP_FORMATOS}Tarjeta/Colectividad?guid=${this.idcolectividad}`
      var newTab = window.open(urltarjet);
      if (newTab) {
        newTab.focus();
        this.$loading(false)
      }
    }
  }
}

</script>
<style lang="scss">
.card {
  width: 180px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.card img {
  width: 100%;
  height: auto;
}

.card-header {
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  background-color: #9cbcd7;
}

.card-body {
  padding: 8px;
  font-size: 10px;
  font-weight: bold;
  color: #545556;
}

.card-footer {
  padding: 16px;
  text-align: right;
  background-color: #f5f5f5;
}

.card-footer button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.card-footer button:hover {
  background-color: #0056b3;
}


.v-data-table {
  overflow-x: auto;
  /* Hace que la tabla sea desplazable horizontalmente si es necesario */
}

.mini-card {
  width: 120px;
  /* Ajusta el ancho */
  height: 80px;
  /* Ajusta la altura */
  font-size: 0.2rem;
  /* Tamaño de texto más pequeño */
}
</style>
