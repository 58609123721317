<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" lg="8" md="8" sm="12">
        <!-- <p class="azul_med--text text-h4">Sitio agente</p> -->
      </v-col>
      <v-col cols="12" lg="4" md="8" sm="12">
        <v-row>
          <v-col class="text-h5  grey--text" cols="4">
            <v-list-item-subtitle>Emitidas</v-list-item-subtitle>
            0
          </v-col>
          <v-col class="text-h5 green--text" cols="4">
            <v-list-item-subtitle>Activas</v-list-item-subtitle>
            {{ this.Activas }}
          </v-col>
          <v-col class="text-h5  azul_med--text" cols="4">
            <v-list-item-subtitle>Expiradas</v-list-item-subtitle>
            {{ this.Expiradas }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <p class="grey--text text-h5">Listado cotizaciones</p>
    <v-row no-gutters>
      <v-col cols="4">
        <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
          outlined dense @change="comp_change()"></v-text-field>
      </v-col>
      <v-col cols="8" class="text-end">
        <v-btn color="azul_med" type="submit" dark elevation="0" :to="{ name: 'cotizacion' }">Nueva cotización</v-btn>
      </v-col>
    </v-row>

    <v-data-table fixed-header calculate-widths :headers="headers" :items="listItems" item-key="id" :search="search">
      <!-- @click:row="handleRowClick" -->
      <template v-slot:[`item.Estado`]="{ item }">
        <span v-if="item.Estado === 0" class="estatus-expirado">Expirado</span>
        <span v-if="item.Estado === 1" class="estatus-activo">Activo</span>
        <span v-if="item.Estado === 2" class="estatus-emitido">Emitida</span>
      </template>
      <template v-slot:[`item.Creado`]="{ item }">
        <span>{{ item.Creado }}</span>
      </template>
      <template v-slot:[`item.Plan`]="{ item }">
        <span class="creado-style">{{ item.Plan }}</span>
      </template>
      <!-- <template v-slot:[`item.Id`]="{ item }">
          <router-link class="grey--text" :to="{ name: 'cotizacion-detalle', params: { guid: item.Id } }">VER</router-link>  |
          <router-link class="grey--text" :to="{ name: 'cotizacion', params: { guid: item.Id } }" v-if="item.Estado === 1"><span>EDITAR</span></router-link> -->
      <!-- <strong @click="DeleteItem(item.id)" class="grey--text"><span>Descargar</span></strong> -->

      <!-- <v-icon small class="mr-2" @click="show(item)">
                      mdi-pool
                  </v-icon>
                  <v-icon small class="mr-2" @click="edit(item)">
                      mdi-pencil
                  </v-icon> -->
      <!-- </template> -->
      <template v-slot:[`item.Id`]="{ item }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn red icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="{ name: 'cotizacion-detalle', params: { guid: item.Id } }">
              <v-list-item-title>
                <v-icon dense color="grey darken-2">
                  mdi-file-document
                </v-icon> Ver cotización
              </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'cotizacion', params: { guid: item.Id } }" v-if="item.Estado === 1">
              <v-list-item-title><v-icon dense color="grey darken-2">
                  mdi-file-edit
                </v-icon> Editar cotización</v-list-item-title>
            </v-list-item>
            <v-list-item @click="DescargaCotizacion(item.Id)">
              <v-list-item-title> <v-icon dense color="grey darken-2">
                  mdi-file-download
                </v-icon> Descargar cotización</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item @click="editItem(item)">
              <v-list-item-title>Enviar cotización</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import EndPointNet from '@/functions/axios/axios-bearer'
import { mapGetters } from 'vuex'
export default {
  name: 'InicioAgente',
  computed: {
    ...mapGetters(['getName', 'getApellidos', 'getEmail'])
  },
  mounted() {
    this.getUser()
    this.emailAgent = this.getEmail
    this.nombreAgent = `${this.getName} ${this.getApellidos}`
  },
  updated() {
  },
  data: () => ({
    emailAgent: '',
    nombreAgent: '',
    IdCot: null,
    usuario: {},
    search: '',
    listItems: [{}],
    Expiradas: 0,
    Activas: 0,
    headers: [

      { text: 'Folio', value: 'Folio' },
      { text: 'Plan', value: 'Plan' },
      { text: 'Pago seleccionado', value: 'Fraccion' },
      { text: 'Nombre', value: 'Nombrecompleto' },
      { text: 'Vigencia', value: 'FecVigFormat' },
      { text: 'Creado', value: 'Creado' },
      { text: 'Estatus', value: 'Estado', sortable: false },
      { text: 'Opciones', value: 'Id', sortable: false },
    ]
  }),
  methods: {
    DescargaCotizacion(item) {
      this.$globalMethod(item, this.nombreAgent, this.emailAgent)
    },
    getUser() {
      EndPointNet(
        `Cotizacion/PorUsuario`, //${localStorage.getItem('id')}
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.listItems = response.data
            this.Activas = this.listItems.filter(item => item.Estado === 1).length;
            this.Expiradas = this.listItems.filter(item => item.Estado === 0).length;
            // this.Cotizacion = response.data
          }
        }
      })
        .catch(() => {
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Cotización no encontrada <b>GUID incorrecto</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
          })
        });
    }
  }
}
</script>
<style scoped>
.creado-style {
  font-weight: bold;
  color: #696969;
}
</style>