<template>
 <v-container fluid>
    <v-parallax :height="Parallax.height" dark src="https://medicover.mx/img/fondo-doctor.png">
      <div class="responsive-text-h1">
        Nuestros Planes
        </div>
        <v-row dense>
          <v-col v-for="card in cards" :key="card.title" cols="12" md="2" sm="2" xs="2">
            <v-card class="azulfuerte">
              <v-card-title v-text="card.title" class="white--text align-end"></v-card-title>
              <!-- <v-img
              :src="card.src"
              class="white--text align-end p-6"
              height="160px"
              
            >
            </v-img> -->
              <v-card-text class="white--text">Descripción</v-card-text>
              <v-card-subtitle v-if="card.title === 'Plus Familiar'" class="white--text" style="font-size: 12px;">
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de urgencias<br />
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de consultas<br />
                <v-icon size="12px" class="white--text">check</v-icon> Monto de urgencias $60, 000.00 MX<br />
                <v-icon size="12px" class="white--text">check</v-icon> Consultas médicas con especialistas 6 LUC<br />
                <v-icon size="12px" class="white--text">check</v-icon> Agrega beneficiarios familiares directos<br />
                <v-icon size="12px" class="white--text">check</v-icon>Atención de urgencia médicas en toda la red
                médica<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura de Dental<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura visual<br />
              </v-card-subtitle>
              <v-card-subtitle v-if="card.title === 'Plus Individual'" class="white--text" style="font-size: 12px;">
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de urgencias<br />
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de consultas<br />
                <v-icon size="12px" class="white--text">check</v-icon> Monto de urgencias $60, 000.00 MX<br />
                <v-icon size="12px" class="white--text">check</v-icon> Consultas médicas con especialistas 6 LUC<br />
                <v-icon size="12px" class="white--text">check</v-icon> Especializado para menores de 50 años<br />
                <v-icon size="12px" class="white--text">check</v-icon> Atención de urgencia médicas en toda la red
                médica<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura de Dental<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura visual<br />
              </v-card-subtitle>
              <v-card-subtitle v-if="card.title === 'Plus Individual 50'" class="white--text" style="font-size: 12px;">
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de urgencias<br />
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de consultas<br />
                <v-icon size="12px" class="white--text">check</v-icon> Monto de urgencias $60, 000.00 MX<br />
                <v-icon size="12px" class="white--text">check</v-icon> Consultas médicas con especialistas 6 LUC<br />
                <v-icon size="12px" class="white--text">check</v-icon> Especializado para mayores de 50 años<br />
                <v-icon size="12px" class="white--text">check</v-icon> Atención de urgencia médicas en toda la red
                médica<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura de Dental<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura visual<br />
              </v-card-subtitle>
              <v-card-subtitle v-if="card.title === 'La Bene Familiar'" class="white--text" style="font-size: 12px;">
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de urgencias<br />
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de consultas<br />
                <v-icon size="12px" class="white--text">check</v-icon> Monto de urgencias $60, 000.00 MX<br />
                <v-icon size="12px" class="white--text">check</v-icon> Consultas médicas con especialistas 6 LUC<br />
                <v-icon size="12px" class="white--text">check</v-icon> Agrega beneficiarios familiares directos<br />
                <v-icon size="12px" class="white--text">check</v-icon> Atención de urgencia médicas en La Beneficencia
                Española<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura de Dental<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura visual<br />
              </v-card-subtitle>
              <v-card-subtitle v-if="card.title === 'La Bene Individual 50'" class="white--text" style="font-size: 12px;">
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de urgencias<br />
                <v-icon size="12px" class="white--text">check</v-icon> Cobertura de consultas<br />
                <v-icon size="12px" class="white--text">check</v-icon> Monto de urgencias $60, 000.00 MX<br />
                <v-icon size="12px" class="white--text">check</v-icon> Consultas médicas con especialistas 6 LUC<br />
                <v-icon size="12px" class="white--text">check</v-icon> Especializado para mayores de 50 años<br />
                <v-icon size="12px" class="white--text">check</v-icon> Atención de urgencia médicas en La Beneficencia
                Española<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura de Dental<br />
                <v-icon size="12px" class="white--text">check</v-icon> Puedes agregar cobertura visual<br />
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn class="text-none black--text" min-width="92" size="large" variant="outlined"
                  @click='cotizar(card.url)'>
                  COTIZAR
                </v-btn> -->
                <!-- <v-btn class="white--text" icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>

              <v-btn class="white--text" icon>
                <v-icon>mdi-bookmark</v-icon>
              </v-btn> -->

                <v-btn class="white--text" @click="agregarCarrito(card.id)" icon>
                  <v-icon>mdi-cart-plus</v-icon>
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </v-parallax>
  </v-container>
</template>
<script>
export default {
  name: 'SeccionPlanesComponent',
  data: function () {
    return {
      cards: [
        {
          id:'1',
          src: `${process.env.VUE_APP_BASE_IMG}/membresias/PlusFamiliar.png`,
          title: 'Plus Familiar',
          url: '/cotizador/1/4'
        },
        {
          id:'2',
          src: `${process.env.VUE_APP_BASE_IMG}/membresias/PlusIndividual.png`,
          title: 'Plus Individual',
          url: '/cotizador/2/4'
        },
        {
          id:'3',
          src: `${process.env.VUE_APP_BASE_IMG}/membresias/PlusIndividual50.png`,
          title: 'Plus Individual 50',
          url: '/cotizador/3/4'
        },
        {
          id:'4',
          src: `${process.env.VUE_APP_BASE_IMG}/membresias/LaBeneFamiliar.png`,
          title: 'La Bene Familiar',
          url: '/cotizador/4/4'
        },
        {
          id:'5',
          src: `${process.env.VUE_APP_BASE_IMG}/membresias/LaBeneIndividual50.png`,
          title: 'La Bene Individual 50',
          url: '/cotizador/5/4'
        }
      ],
    };
  },
  methods: {
    cotizar(url) {
      this.$router.push(url)
    },
    agregarCarrito(id) {
      console.log(id)
      localStorage.setItem('NumCarrito', 1);
    }
  },
  computed: {
    Parallax() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return {
            height: '1000px',
          };
        case 'sm':
          return {
            height: '1000px',
          };
        case 'lg':
          return {
            height: '700px',
          };
        case 'xl':
          return {
            height: '800px',
          };
        default:
          return {
            height: '700px',
          };
      }
    },
  },
}
</script>
<style scoped>
.v-card:hover {
  background-color: #0a3d85af !important;
}

@keyframes card-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
</style>