<template>
    <v-container>
        <div v-if="this.Membresia.Id">
            <p class="gris--text text-h4">Datos de la membresía: </p>
            <v-form ref="form" @submit.prevent="submitForm">
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="Membresia.Id" class="mayuscula" label="Membresía" outlined
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="Membresia.Estatus" :class="asignaClase" label="Estatus" outlined
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="Membresia.Plan" class="mayuscula" label="Plan" outlined
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="Membresia.Titular" class="mayuscula" label="Titular" persistent-hint
                            outlined readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="Membresia.VigFin" class="mayuscula" label="Vigencia" persistent-hint
                            outlined readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="Membresia.Coberturas" class="mayuscula" label="Coberturas"
                            persistent-hint outlined readonly></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <div v-else style="display: grid; place-items: center; height: 200px;">
            <p class="gris--text text-h3">Error, número de membresía no válido.</p>
            <p class="gris--text text-h5">Pedes vuelver a escanear el código <strong>QR</strong> de la membreísa.</p>
        </div>
    </v-container>
</template>

<script>
import ApiBase from '@/functions/axios/axiosNetBase'
export default {
    name: 'CotizadorPaso2View',
    components: {
        // Beneficiario: () => import('@/components/Forms/BeneficiarioComponent.vue')
    },
    created() {
        let membresia = this.$route.params.membresia;
        if (membresia) {
            this.Membresia.id = membresia
            this.BuscaMembresia()
        }
    },
    computed: {
        asignaClase() {
            if (this.Membresia.Estatus == "ACTIVA") {
                return 'estatus-activo';
            } else {
                return 'estatus-expirado';
            }
        }
    },
    data: () => ({
        Membresia: {
            Membresia: null,
            Titular: "",
            VigFin: "",
            Plan: "",
            Coberturas: "",
            Estatus: ""
        }
    }),
    mounted() {
    },
    methods: {
        BuscaMembresia() {
            this.$loading(true)
            ApiBase(
                `getMembresia/${this.Membresia.id}`,
                'GET'
            ).then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        console.log(response.data)
                        this.Membresia = response.data
                        this.Membresia.Id = response.data.Membresia
                        this.$loading(false)
                    }
                }else{
                    this.$loading(false)
                }
            })
                .catch(() => {
                    this.$swal({
                        position: '',
                        html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        showCloseButton: true,
                        showConfirmButton: true,
                    })
                    this.$loading(false)
                });

        }
    },

}
</script>