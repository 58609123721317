<template>
    <v-container>
      <v-layout justify-center>
        <v-flex xs12 sm8 md4>
          <div class="text-h4 grey--text">Inicia sesión</div>
          <v-img src="https://medicover.mx/img/imagen-login.webp" max-height="140" cover
            class="bg-grey-lighten-2"></v-img>
          <br/>
          <p class="grey--text">Nos <strong>renovamos para tí</strong> , disfruta la experiencia de estar protegido con MEDICOVER. </p>
          <div class="text-h6 grey--text">Ingresa el número de tu membresía</div>
          <form @submit.prevent="login">
            <v-text-field outlined clearable dense v-model="membresia"  label="Número de membresía"
            required></v-text-field>

            <v-text-field v-show="vercorreo" outlined clearable dense v-model="email" :rules="emailRules" label="Correo electrónico registrado"
              required></v-text-field>
              <v-text-field
              v-model="this.password"
              :rules="[rules.required, rules.min]"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-2"
              label="Crea una contraseña"
              hint="At least 8 characters"
              class="input-group--focused"
              @click:append="show1 = !show1"
              outlined clearable dense
            ></v-text-field>
            <v-text-field
              v-model="this.password2"
              :rules="[rules.required, rules.min]"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="input-10-2"
              label="Vuelve a escribirla"
              hint="At least 8 characters"
              class="input-group--focused"
              @click:append="show2 = !show2"
              outlined clearable dense
            ></v-text-field>
              <v-text-field v-show="vercorreo" outlined clearable dense label="Crea una contraseña" type="password" :rules="contrarquerido"
              v-model="password" required></v-text-field>
              <v-text-field v-show="vercorreo" outlined clearable dense label="vuelve a escribir tu contraseña" type="password" :rules="contrarquerido"
              v-model="password2" required></v-text-field>
              <v-alert dense text type="error" v-show="this.mostrarerror">
              {{ this.errormensaje }}
            </v-alert>
            <div class="d-flex flex-column justify-space-between align-center">
              <v-btn color="azul_med" depressed elevation="1" large outlined rounded @click="siguiente">
                SIGUIENTE</v-btn>
            </div>
  
            <!-- <v-text-field outlined clearable dense v-model="email" :rules="emailRules" label="Correo electrónico"
              required></v-text-field>
            <v-text-field outlined clearable dense label="Contraseña" type="password" :rules="contrarquerido"
              v-model="password" required></v-text-field>
            <v-alert dense text type="error" v-if="this.mostrarerror">
              {{ this.errormensaje }}
            </v-alert> -->
            <!-- <a 
                  href="/usuario-membresia"
                  style="color:gray;font-weight: bold;text-decoration: none;">¿No tienes? Activa tu cuenta con tu membresía, da clic aquí.</a>
            <br/>-->
            <!-- <v-spacer></v-spacer>
            <br />
            <div class="d-flex flex-column justify-space-between align-center">
              <v-btn color="azul_med" depressed elevation="2" large outlined rounded type="submit">
                Iniciar sesión</v-btn>
            </div> -->
  
          </form>
        </v-flex>
      </v-layout>
      <v-row class="text-center mt-2">
        <v-col cols="12">
          <a target="_blank"
                  :href="this.WhatsApp"  
                  style="color:gray;font-weight: bold;">¿Necesitas ayuda?¿no tienes registrado tu correo? da clic aquí</a>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  // const { validationMixin } = require('vuelidate')
  // const { required, maxLength, email } = require('vuelidate/lib/validators')
  
  export default {
    name: 'LoginView',
    components: {
      // ValidationProvider,
      // ValidationObserver,
    },
    // mixins: [validationMixin],
  
    validations: {
      // password: { required, maxLength: maxLength(1) },
      // email: { required, email }
    },
    data: () => ({
      show1: false,
      show2: false,
      vercorreo: false,
      Membresia : null,
      WhatsApp: `https://api.whatsapp.com/send?phone=${process.env.VUE_APP_BASE_PHONEWHATS}&text=Hola`,
      email: 'existe@micorreo.com',
      mostrarerror: false,
      password: '',
      password2: '',
      errormensaje: null,
      emailRules: [
        (v) => !!v || 'Correo requerido',
        (v) => /.+@.+\..+/.test(v) || 'Correo invalido'
      ],
      contrarquerido: [
        (v) => !!v || 'Contraseña requerida'
      ],
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match'),
      }
    }),
    computed: {
      checkboxErrors() {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        // !this.$v.checkbox.checked && errors.push('You must agree to continue!');
        return errors
      },
      selectErrors() {
        const errors = []
        if (!this.$v.select.$dirty) return errors
        // !this.$v.select.required && errors.push('Item is required');
        return errors
      },
      nameErrors() {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        // !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long');
        // !this.$v.name.required && errors.push('Name is required.');
        return errors
      },
      emailErrors() {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        // !this.$v.email.email && errors.push('Must be valid e-mail');
        // !this.$v.email.required && errors.push('E-mail is required');
        return errors
      }
    },
    created() {
      this.$store
        .dispatch('logout', {})
    },
    beforeMount() {
    },
    mounted() {
    },
  
    methods: {
      siguiente(){
        if(this.membresia){
          this.vercorreo = true
          this.mostrarerror = false
        }else{
          this.mostrarerror = true
          this.errormensaje = "El número de tu membresía no es correcto"
        }
      },
      validate() {
        // this.$refs.form.validate();
      },
      login() {
        // this.validate();
        this.$loading(true)
        this.$store
          .dispatch('login', {
            email: this.email,
            password: this.password
          })
          .then((response) => {
            this.$loading(false)
            // this.$router.replace(this.$route.query.redirect || '/');
            this.$router.replace(this.$route.query.redirect || response);
          })
          .catch((error) => {
            console.log(error.response.status)
            this.$loading(false)
            if (error.response.status === 400) {
              this.mostrarerror = true
              this.errormensaje = error.response.data
            } else {
              this.mostrarerror = true
              this.errormensaje = "Ops, tuvimos un error inesperado, intenta mas tarde."
            }
          })
      },
      submit() {
        this.$v.$touch()
      },
      clear() {
        this.$v.$reset()
        this.name = ''
        this.email = ''
      }
    }
  }
  </script>
  <style lang="scss" scoped></style>