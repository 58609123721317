import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import InicioDashboard from '../views/Dashboard/InicioView.vue'
import UserDashboard from '../views/Dashboard/UserView.vue'
import UserEditDashboard from '../views/Dashboard/UserEditView.vue'

import Vacaciones from '../views/Dashboard/Gestionvacaciones.vue'

import AgentMembresias from '../views/Agente/MembresiasView.vue'


import InicioAgent from '../views/Agente/InicioView.vue'
import Cotizacion from '../views/Cotizacion/Paso1View.vue'
import CotizacionBeneficiarios from '../views/Cotizacion/Paso2View.vue'
import CotizacionDetalle from '../views/Cotizacion/CotizacionDetalleView.vue'
import ListadoCotizaciones from '../views/Cotizacion/AllCotizacionesView.vue'
import MembresiasAll from '../views/Membresias/MembresiasAllView.vue'
// import SiniestralidadColectivo from '../views/SiniestralidadView.vue'
import ErrorView from '@/views/ErrorView.vue' 

import Recibos from '../views/Cobranza/RecibosView.vue'

import Empleado from '../views/Empleado/EmpleadoView.vue'
import UploadGrupos from '../views/Membresias/UploadView.vue'

import ValidadorMembresias from '../views/ValidadorMembresiaView.vue'
import Especialidades from '../views/EspecialidadesView.vue'

import AdmCotizaciones from '../views/Administrador/AdmCotizaciones.vue'
import AdmCitas from '../views/Administrador/AdmCitasMedicas.vue'
import AdmHospital from '../views/Administrador/HospitalsView.vue'
import LoginUsuario from '../views/User/login-step.vue'


import { Buffer } from 'buffer';

Vue.use(VueRouter)
function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload)
}
const returnLogin = (to, next) => {
  if (
    localStorage.getItem('token') === null || localStorage.getItem('token') === ''
  ) {
    if (to.fullPath != null) {
      //next('/login')
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        }
      })
    } else {
      next('/login')
    }
  } else {
    //if (localStorage.getItem('token') != null) {
    const jwtPayload = parseJwt(localStorage.getItem('token'));
    if (jwtPayload.exp < Date.now() / 1000) {
      // token expired
      //deleteTokenFromLocalStorage();
      localStorage.removeItem('id')
      localStorage.removeItem('token')
      localStorage.removeItem('nombres')
      localStorage.removeItem('aPaterno')
      localStorage.removeItem('aMaterno')
      localStorage.removeItem('email')

      // next('/login')
      if (to.fullPath != null) {
        //next('/login')
        next({
          path: '/login',
          query: {
            redirect: to.fullPath,
          }
        })
      } else {
        next('/login')
      }
    }
    //}
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'error-view',
    component: ErrorView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: "/valida-membresia",
    name: "valida-membresia",
    component: ValidadorMembresias,
    props: (route) => ({ guid: route.query.guid })  // Pasar el parámetro de consulta como prop
  },
  {
    path: '/especialidades',
    name: 'especialidades',
    meta: { 
      title: 'Especialidades médicas'
     },
    component: Especialidades
  },
  {
    path: '/login-user',
    name: 'loginusuario',
    component: LoginUsuario
  },
 {
   path: '/news',
   name: 'news',
   component: InicioDashboard,
   children: [{
     path: ':id',
     name: 'newsitem',
     component: UserDashboard,
   }]
 },
 /*
 {
  path: "/dashboard",
  name: "dashboard",
  redirect: { name: 'dashboard.inicio' },
  props: true,
  //meta: { layout: 'AdminLayout' },
  //component: InicioDashboard,
  children: [
    { alias: '', path: 'inicio', name: 'dashboard.inicio', component: InicioDashboard, meta: { layout: 'AdminLayout' } },
    { path: 'users', name: 'dashboard.users', component: UserDashboard, meta: { layout: 'AdminLayout' } },
  ],
  beforeEnter(to, from, next) {
    returnLogin(to, next)
  },
},
*/
 {
 path: "/dashboard",
 name: "dashboard",
 meta: { layout: 'AdminLayout' },
 component: InicioDashboard,
 beforeEnter(to, from, next) {
   returnLogin(to, next)
 },
},
{
  path: "/beneficiario",
  name: "usuario-beneficiario",
  meta: { layout: 'AdminLayout' },
  component: InicioDashboard,
  beforeEnter(to, from, next) {
    returnLogin(to, next)
  },
 },
{
 path: "/dashboard/users",
 name: "usuarios",
 props: true,
 meta: { layout: 'AdminLayout' },
 component: UserDashboard,
 beforeEnter(to, from, next) {
   returnLogin(to, next)
 },
},
{
 path: "/dashboard/users/:id?",
 name: "users.edit",
 props: true,
 meta: { layout: 'AdminLayout' },
 component: UserEditDashboard,
 beforeEnter(to, from, next) {
   returnLogin(to, next)
 },
},
{
  path: "/dashboard/vacaciones",
  name: "gestion-vacaciones",
  props: true,
  meta: { layout: 'AdminLayout' },
  component: Vacaciones,
  beforeEnter(to, from, next) {
    returnLogin(to, next)
  },
 },
  {
    path: "/visor-membresias",
    name: "visor-membresias",
    meta: { 
      title: 'Listado de Membresías',
      layout: 'AdminLayout'
     },
    component: MembresiasAll,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    }
  },
  // {
  //   path: '/dashboard/datos-fiscales:uuid',
  //   props: true,
  //   name: 'datos-fiscales',
  //   component: () => import('../views/Dashboard/DatosFiscalesView.vue')
  // },
  
  {
    path: "/upload",
    name: "upload",
    meta: { layout: 'AdminLayout' },
    component: UploadGrupos
  },
  {
    path: "/colaborador",
    name: "colaborador",
    meta: { layout: 'AdminLayout' },
    component: Empleado,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },

  {
    path: "/cobranza",
    name: "cobranza",
    props: true,
    meta: { 
      title: 'Listado recibos',
      layout: 'AdminLayout'
     },
    component: Recibos,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: "/agente-cobranza",
    name: "agente-cobranza",
    props: true,
    meta: { 
      title: 'Listado recibos',
      layout: 'AdminLayout'
     },
    component: InicioAgent,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: "/agente-cotizaciones",
    name: "agente-cotizaciones",
    props: true,
    meta: { layout: 'AdminLayout' },
    component: ListadoCotizaciones,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: "/agente-membresias",
    name: "agente-membresias",
    props: true,
    meta: { 
      title: 'Mis membresías',
      layout: 'AdminLayout'
     },
    component: AgentMembresias,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: "/cotizacion/:guid?",
    // props: true,
    name: "cotizacion",
    meta: { layout: 'AdminLayout', title: 'Cotización' },
    component: Cotizacion,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: "/cotizacion-beneficiarios/:guid?",
    //props: true,
    name: "cotizacion-benef",
    meta: { layout: 'AdminLayout', title: 'Beneficiarios' },
    component: CotizacionBeneficiarios,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: "/cotizacion-detalle/:guid",
    name: "cotizacion-detalle",
    meta: { layout: 'AdminLayout', title: 'Detalle Cotización' },
    component: CotizacionDetalle,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  /*administrador */
  {
    path: "/adm-cotizaciones",
    name: "adm-cotizaciones",
    meta: { layout: 'AdminLayout'},
    component: AdmCotizaciones,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: "/adm-citas",
    name: "adm-cotizaciones",
    meta: { layout: 'AdminLayout', title: 'Administrador citas'},
    component: AdmCitas,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: "/adm-hospitales",
    name: "adm-hospitales",
    meta: { layout: 'AdminLayout'},
    component: AdmHospital,
    beforeEnter(to, from, next) {
      returnLogin(to, next)
    },
  },
  {
    path: '*', // Ruta comodín para capturar todas las rutas no definidas
    name: 'Error',
    component: ErrorView,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  Vue.prototype.$setTitle(to.meta.title);
});

export default router
