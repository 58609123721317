<template>
    <v-container fluid>
        <!-- <v-flex xs12>
            <v-btn class="text-none azul_med" variant="text" elevation="0" dark>
                Agentes
            </v-btn>
            <v-btn class="text-none azul_med" variant="text" elevation="0" dark>
                Usuarios
            </v-btn>
            <v-btn class="text-none azul_med" variant="text" elevation="0" dark>
                Doctores
            </v-btn>
        </v-flex> -->
        <v-flex xs12 sm8 md6 lg4>
            <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
                @change="comp_change()"></v-text-field>
        </v-flex>
        <v-data-table dense fixed-header calculate-widths :headers="headers" :items="listItems" item-key="id"
            :search="search">
            <!-- @click:row="handleRowClick" -->
            <template v-slot:[`item.Estado`]="{ item }">
                <p v-if="item.Estado === 1" class="green--text mb-auto">Activo</p>
                <p v-else class="grey--text mb-auto">Suspendido</p>
            </template>
            <template v-slot:[`item.Roles`]="{ item }">
                <small class="azul_med--text" v-for="rol in item.Roles" :key="rol.Id">
                    {{ rol.NombreRol }}
                </small>
            </template>
            <template v-slot:[`item.Id`]="{ item }">
                <router-link class="grey--text"
                    :to="{ name: 'users.edit', params: { id: item.Id } }">Editar</router-link> |
                <strong @click="DeleteItem(item.id)" class="grey--text">Eiminar</strong>
                <!-- <router-link class="red--text" :to="{ name: 'users.edit', params: { id: item.id } }">Eliminar</router-link> -->
                <!-- <v-icon small class="mr-2" @click="show(item)">
                    mdi-pool
                </v-icon>
                <v-icon small class="mr-2" @click="edit(item)">
                    mdi-pencil
                </v-icon> -->
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
import EndPointNet from '@/functions/axios/axios-bearer'

export default {
    name: 'User',
    async mounted() {
        //await this.getUsuarios()
    },
    created() {
        this.getUsuarios()
    },
    data: () => ({
        search: '',
        listItems: [{
            rolesUser: [{}]
        }],
        headers: [
            { text: 'Nombres ', value: 'Nombres' },
            {
                text: 'Paterno',
                align: 'start',
                sortable: true,
                value: 'APaterno'
            },
            { text: 'Materno', value: 'AMaterno' },
            { text: 'Correo', value: 'Email' },
            { text: 'Celular', value: 'NumTel' },
            { text: 'Roles ', value: 'Roles' },
            { text: 'Estatus ', value: 'Estado' },
            { text: 'Opciones', value: 'Id' },
        ]
    }),
    methods: {
        comp_change() {
        },
        async getUsuarios() {
            this.$loading(true)
            await EndPointNet(
                'user',
                'GET'
            ).then((response) => {
                this.listItems = response.data
                this.$loading(false)
            })
                .catch((error) => {
                    console.log(error)
                    this.$loading(false)
                });
            // console.log(response)
            // if (response.status === 200) {
            //     this.listItems = response.data
            //     this.$loading(false)
            // }else{
            //     this.$loading(false)
            // }

        },
        handleRowClick(item) {
            alert(item)
        },
        DeleteItem(item) {
            console.log(item)
            this.$swal({
                title: '¿Deseas Eliminar este registro ?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: `Cancelar`,
                denyButtonText: `Eliminar`
            }).then((result) => {
                if (result.isConfirmed) {
                    // this.$swal.fire("Saved!", "", "success");
                } else if (result.isDenied) {
                    this.$swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Elemento fue eliminado ",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            })
        }
    }
}
</script>