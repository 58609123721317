const CurrencyFormatPlugin = {
    install(Vue) { //options
      
      Vue.prototype.$formatCurrency = function (value, currencyCode = 'USD') {
        const formattedValue = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currencyCode,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
  
        return formattedValue;
      };
    },
  };
  
  export default CurrencyFormatPlugin;