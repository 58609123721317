<template>
    <v-container fluid>
        <!-- {{ $route.params.id }} -->
        <!-- {{ Usuario }} -->
        <v-row no-gutters>
            <v-col>
                <DatosPersonales :propObjUser="Usuario"></DatosPersonales>
            </v-col>
            <v-col cols="4">
                <Roles :User="$route.params.id"></Roles>
            </v-col>
            <!-- <Direccion :Direccion="Usuario.direccion_id"></Direccion> -->
                <Direccion :propObjUserDireccion ="Usuario.Direccion"></Direccion> 
        </v-row>
        
        <template v-for="(item, i) in Usuario.Roles">
            <v-row :key="i" cols="12">
                <v-col v-if="item.NombreRol === 'Agente'" >
                    <div ref="agentComponent">
                        <Agente :User=$route.params.id></Agente>
                    </div>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>
<script>
import EndPointNet from '@/functions/axios/axios-bearer'
export default {
    name: 'User',
    components: {
        DatosPersonales: () => import('@/components/DatosPersonales.vue'),
        Roles: () => import('@/components/RolesComponent.vue'),
        Agente: () => import('@/components/AgenteComponent.vue'),
        Direccion: () => import('@/components/DireccionComponent.vue')
    },
    created() {
        this.$root.$on('SetRoles', (data) => {
            console.log(data)
            if(data){
                //this.$refs.agentComponent.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            this.getUser();
        });
        this.$root.$on('setDireccionUser', async (data) =>  {
            console.log("idDireccion:"+data)
             await EndPointNet(
                    `/User/AsignaDireccion`,
                    'POST',
                    {
                        id: this.$route.params.id,
                        direccion: data
                    }
                ).then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        
                    }
                    this.$loading(false)
                })
                    .catch(() => {
                        // console.log(error)
                        // throw error.response.data;
                        this.$loading(false)
                    });
         });
    },
    mounted() {
        this.getUser()
    },
    updated() {
    },
    data: () => ({
        Usuario: {
            Id: '',
            Nombres: '',
            APaterno: '',
            AMaterno: '',
            RFC: '',
            CURP: '',
            Email: '',
            FecNac: '',
            Estado: '',
            UrlFoto: '',
            NumTel: '',
            datosfiscales_id: '',
            direccion_id: '',
            Direccion: {
                CP: '',
                Estado: '',
                Municipio: '',
                Asentamiento: '',
                Calle:'',
                Numint: '',
                Numext: ''
            },
            Roles: []
        }
    }),
    methods: {
        async getUser() {
            if (this.$route.params.id) {
                this.$loading(true)
                await EndPointNet(
                    `user/${this.$route.params.id}`,
                    'GET'
                ).then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        this.Usuario = response.data
                    }
                    this.$loading(false)
                })
                    .catch(() => {
                        // console.log(error)
                        // throw error.response.data;
                        this.$loading(false)
                    });
                /*
                    if (response.status === 200) {
                        // console.log(response.data)
                        // this.usuario ={...response.data[0]}
                        this.usuario = response.data[0]
                        console.log(this.usuario)
                        this.$loading(false)
                    }
                */
            }
        }
    }
}
</script>