
import axios from 'axios'
/**API PARA CONSULTAS DE BASE DE DATOS INICIAL MEDICOVER */
const config = {
  method: 'GET',
  baseURL: `${process.env.VUE_APP_BASE_URLTRANSACCIONAL}`,
  headers: {
    'Content-Type': 'application/json'
  }
}

export default async function ApiBase (url, method = 'GET', datos) {
  config.method = method
  config.data = datos
  return axios(url, config)
}
