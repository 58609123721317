import axios from 'axios'
// import VueCookies from 'vue-cookies';
axios.defaults.baseURL = `${process.env.VUE_APP_BASE_API}`
axios.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  //'Access-Control-Allow-Headers': 'GET, POST, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
  //'Content-Type': 'application/json'
}
const stateUser = {
  currentUser: {
    id: localStorage.getItem('id'),
    nombres: localStorage.getItem('nombres'),
    aPaterno: localStorage.getItem('aPaterno'),
    aMaterno: localStorage.getItem('aMaterno'),
    email: localStorage.getItem('email'),
    codAge: localStorage.getItem('codAge'),
    menu: localStorage.getItem('menu'),
    //urlBase: localStorage.getItem('urlBase'),
    //RFC: localStorage.getItem('RFC'),
  },
  //roles: JSON.parse(localStorage.getItem('roles')),
  acceptcookie: localStorage.getItem('acceptcookie'),
  token:
    localStorage.getItem('token') != null
      ? localStorage.getItem('token')
      : sessionStorage.getItem('token'),
  id: 0
}

const gettersUser = {
  getName: (state) => `${state.currentUser.nombres}`,
  getApellidos: (state) => `${state.currentUser.aPaterno}`,
  getEmail: (state) => state.currentUser.email,
  isLogged: (state) => !!(state.token),
  getIDE: (state) => state.currentUser.id,
  //getRFC: (state) => state.currentUser.RFC,
  getCookie: (state) => !!(state.acceptcookie),
  getCODAGE: (state) => state.currentUser.codAge,
  getMenuOpcions: (state) => state.currentUser.menu,
  //geturlBase: (state) => state.currentUser.urlBase,
  // getRoles: (state) => state.roles,
  //inRole: (state) => (id) => state.roles.find((thing) => thing === id)
}

const mutationsUser = {
  ACCEPT_COOKIE(state, acceptcookie) {
    localStorage.setItem('acceptcookie', `${acceptcookie}`)
    state.acceptcookie = acceptcookie
  },
  LOGIN_USER(state, userData) {
    localStorage.setItem('id', userData.id)
    localStorage.setItem('nombres', `${userData.nombres}`)
    localStorage.setItem('aPaterno', userData.aPaterno)
    localStorage.setItem('aMaterno', userData.aMaterno)
    localStorage.setItem('email', `${userData.email}`)
    localStorage.setItem('token', userData.token)
    //localStorage.setItem('roles', JSON.stringify(userData.roles))
    localStorage.setItem('codAge', userData.codAge)
    localStorage.setItem('menu', userData.menu)
    //localStorage.setItem('urlBase', userData.urlBase)


    state.currentUser.Id = userData.id
    state.currentUser.nombres = userData.nombres
    state.currentUser.aPaterno = userData.aPaterno
    state.currentUser.aMaterno = userData.aMaterno
    // state.currentUser.RFC = userData.RFC
    state.currentUser.email = userData.email
    state.currentUser.token = userData.token
    state.currentUser.codAge = userData.codAge
    state.currentUser.menu = userData.menu
    // state.currentUser.urlBase = userData.urlBase
    //state.roles = userData.roles

    //axios.defaults.headers.common.Authorization = `Bearer ${userData.token}` //super importante
  },
  LOGOUT_USER() {
    localStorage.removeItem('id')
    localStorage.removeItem('token')
    localStorage.removeItem('nombres')
    localStorage.removeItem('aPaterno')
    localStorage.removeItem('aMaterno')
    // localStorage.removeItem('remember')
    localStorage.removeItem('email')
    localStorage.removeItem('codAge')
    localStorage.removeItem('menu')
    //localStorage.removeItem('urlBase')
    // localStorage.removeItem('RFC')
    // localStorage.removeItem('roles')
    // state.token = null
    // state.roles = null 
    // state.currentUser = []
  }
}

const actions = {
  async login({ commit }, credentials) {
    return axios.post('/Auth', credentials).then(({ data }) => {
      if (data) {
        commit('LOGIN_USER', data)
        return data.url
      }
    });
  },

  logout({ commit }) {
    commit('LOGOUT_USER')
  }

}

export default {
  state: stateUser,
  mutations: mutationsUser,
  getters: gettersUser,
  actions
}
