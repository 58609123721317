<template>
 <v-container fluid>
    <!-- <v-btn :to="{ name: 'usuarios', params: { id: this.$route.params.id } }">Usuarios
    </v-btn> -->
    <MenuFull></MenuFull>
 </v-container>
</template>
<script>
export default {
  name: 'Inicio',
  components: {
        MenuFull: () => import('@/components/MenuFullPage.vue')
    },
}

</script>
