<template>
    <v-container fluid>
        <!-- <div class="text-h4 text-md-h4 text-lg-h4 gris--text">Listado recibos</div> -->
        <p>Listado de hospitales Red MediCover.</p>
        <capacitacion :isOpen.sync="isModOpen" :propId="selectedRow"></capacitacion>
        <div class="cont-bord-gris">
            <v-row no-gutters justify="end">
                <v-col xs="6" sm="6" md="4" lg="4" xl="3">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar en tabla" single-line
                        hide-details outlined dense @change="comp_change()"></v-text-field>
                </v-col>
            </v-row>
            <v-data-table :items-per-page="-1" fixed-header calculate-widths :headers="headers" :items="data"
                :search="search" item-key="Id" class="custom-data-table" :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Filas por página'
                }" no-data-text="No hay datos disponibles" @click:row="selectRow">
                <template v-slot:[`item.ops`]="{ item }">
                    <v-menu offset-x left :close-on-content-click="true">
                        <template v-slot:activator="{ on, attrs }">
                            <td><v-btn @click="selectRow(item)"
                                    :class="{ 'selected-row': selectedRow && selectedRow === item.Ide }" red icon
                                    v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                                </v-btn></td>
                        </template>
                        <v-list>
                            <v-list-item @click="addCap(item.Id)"><v-icon left dense color="grey darken-2">
                                    mdi-eye-outline
                                </v-icon> Registrar capacitación
                            </v-list-item>
                            <v-list-item @click="verCap(item.Id)"><v-icon left dense color="grey darken-2">
                                    mdi-list-box-outline
                                </v-icon> Ver capacitaciones
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </div>
    </v-container>
</template>
<script>
import EndPointNet from '@/functions/axios/axios-bearer'
export default {
    name: 'HospitalComponent',
    components: {
        capacitacion: () => import('@/components/Forms/CapacitacionComponent.vue')
    },
    mounted() {
        this.getData()
    },
    data: () => ({
        selectedRow: '',
        isModOpen: true,
        search: '',
        data: [],
        headers: [
            { text: 'Id', value: 'Ide', width: '10%' },
            { text: 'Nombre', value: 'Nombre' },
            { text: 'Telefono', value: 'Tel' },
            { text: 'Correo', value: 'Correo' },
            { text: 'Contrato', value: 'UrlContrato' },
            { text: 'Direccion', value: 'IdDireccion', sortable: false },
            { text: 'Datos Fiscales', value: 'DatosFiscales', sortable: false },
            { text: 'Estatus', value: 'Estado' },
            { text: 'Modificado', value: 'updated_at', sortable: false },
            { text: 'Opciones', value: 'ops', sortable: false },
        ],
    }),
    methods: {
        addCap() {
            this.isModOpen = true
        },
        verCap() {
            this.isModOpen = true
        },
        selectRow(item) {
            console.log(item.Ide)
            this.selectedRow = item;
        },
        getData() {
            this.$loading(true)
            EndPointNet(
                'Hospital',
                'GET'
            ).then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        this.$loading(false)
                        this.data = response.data
                    }
                }
            })
                .catch(() => {
                    this.$loading(false)
                    this.$swal({
                        position: '',
                        html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        showCloseButton: true,
                        showConfirmButton: true,
                        //timer: 1500,
                    })
                });
        },
        registrarPago(item) {
            console.log(item)
            this.isModalPagoOpen = true
        }
    }
}
</script>
<style scoped>
.creado-style {
    font-weight: bold;
    color: #696969;
}

.impago-row .v-data-table__wrapper tbody tr {
    background-color: rgb(220, 239, 220) !important;
}

.custom-data-table .pagado-row {
    background-color: rgb(220, 239, 220) !important;
}

.custom-data-table .impago-row {
    background-color: rgb(255, 255, 25) !important;
}

.custom-data-table .older-row {
    background-color: #ffe4e1;
    /* Color para filas con edad > 30 */
}

.custom-data-table .younger-row {
    background-color: #e0ffff;
    /* Color para filas con edad <= 30 */
}

.elemento-pagado {
    background-color: #c8e8c8;
}

.small-chip {
    font-size: 10px;
    color: #575757;
}

.selected-row {
    background-color: #a0d0f4 !important;
    /* Color de fondo para la fila seleccionada */
}
</style>