<template>
    <v-container>
        <h1>Calendario</h1>
        <large-calendar></large-calendar>
    </v-container>
    </template>
      
      <script>
      export default {
        name: 'vacaciones',
        components: {
          LargeCalendar: () => import('@/components/CalendarComponent.vue')
        },
      };
      </script> 