<template>
      <v-main>
        <v-container class="d-flex justify-center align-center text-center">
          <v-card>
            <v-card-title class="headline">
              <p class="headline">Error 404 - Page Not Found</p>
            </v-card-title>
            <v-card-text>
              <h3>La página que estás buscando no existe o ha ocurrido un error.</h3>
            </v-card-text>
            <v-card-actions>
              <v-btn dark color="azulfuerte" @click="goHome">Ir a inicio</v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-main>
  </template>
<script>

export default {
    name: 'ErrorView',
    methods:{
        goHome(){
             this.$router.push({ name: 'home' })
        }
    }
}
</script>
<style lang="scss" scoped>
.v-container {
  height: 70vh;
  background-color: #2294c9;
}
.v-card {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}
.headline{
  color: rgb(156, 156, 156);
}
</style>