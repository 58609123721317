import axios from 'axios'

const config = {
  method: 'GET',
  baseURL: `${process.env.VUE_APP_BASE_API}`,
  headers: {
    'Content-Type': 'application/json'
  }
}

export default async function EndPointNet (url, method = 'GET', datos) {
  config.method = method
  //config.data = {... datos,   email :localStorage.getItem('email') }
  config.data = datos
  // console.log(config.data)
  if (localStorage.getItem('token') !== '' || localStorage.getItem('token') !== null || localStorage.getItem('token') !== undefined) {
    config.headers.common = { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }
  return axios(url, config)
}
