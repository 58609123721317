<template>
    <v-container>
    <v-file-input
      v-model="file"
      label="File input"
      filled
      @change="uploadFile"
    ></v-file-input>
  </v-container>
</template>
  <script>
  // import apiGET from '@/functions/axios/axiosis'
  export default {
  mounted () { 
  },
  methods: {
    uploadFile() {
      if (!this.file) return;

      const formData = new FormData();
      formData.append('file', this.file);

      fetch('https://localhost:7064/api/LectorExcel/Uploadgrupos', {
        method: 'POST',
        body: formData,
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(err => console.error('Error:', err));
    }
  },
  data: () => ({
    file: null,
  }),
  }
  </script>
    <style lang="scss" scoped></style>
  